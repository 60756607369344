import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "100px",
  },
  mainDiv: {
    backgroundImage: `url("images/CurveLine.svg")`,
    backgroundAttachment: "cover",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    resizeMode: "contain",

    // [theme.breakpoints.down("md")]: {
    //   marginTop: "20px",
    //   height: "800px",
    //   backgroundImage: `url("images/RectLight.svg")`,
    //   backgroundAttachment: "cover",
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "100% auto",
    //   resizeMode: "contain",
    // },
  },
  landingimage2: {
    width: "350px",
    [theme.breakpoints.down("md")]: {
      width: "250px",
    },
  },
  landingimage: {
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
      width: "500px",
    },

    [theme.breakpoints.down("md")]: {
      width: "300px",
      marginLeft: "50px",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  fontname2: {
    [theme.breakpoints.up("md")]: {
      fontFamily: "poppins",
      width: "60%",
      fontSize: "18px",
      marginTop: 40,
      color: "#111029",
      marginLeft: "50px",
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      color: "black",
      textAlign: "center",
    },
  },
  text1: {
    [theme.breakpoints.up("lg")]: {
      marginTop: 50,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginTop: "80px",
      marginBottom: "80px",
    },
  },
  getstarted: {
    width: "150px",
    height: "40px",
    backgroundColor: "#3C4564",
    marginLeft: "50px",
    borderRadius: 8,
    color: "#F5AE31",
    fontFamily: "poppins",
    "&:hover": {
      backgroundColor: "#3c4564",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
  },
  appbar: {
    backgroundColor: "transparent",
  },
  powered: {
    fontFamily: "poppins",
    fontSize: 18,
    marginLeft: "50px",
    marginTop: 40,
    color: "#111029",
    [theme.breakpoints.down("md")]: {
      color: "white",
      marginLeft: "20px",
    },
  },
  maintoolbar: {
    marginLeft: 45,
  },
  toolbar: {
    color: "black",
    "&:hover": {
      backgroundColor: "white",
      color: "#33a1b2",
    },
  },
  toolbar2: {
    color: "black",
    "&:hover": {
      backgroundColor: "#85D7ED",
      color: "white",
    },
  },
  ourpurpose: {
    fontFamily: "poppins",
    [theme.breakpoints.up("md")]: {
      fontSize: "42px",
      fontWeight: 600,
      color: "#111029",
      lineHeight: "56px",
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      lineHeight: "46px",
      fontSize: "32px",
      fontWeight: 600,
      color: "#111029",
      margin: "20px",
    },
  },
  servicesimage: {
    width: 400,
    [theme.breakpoints.down("md")]: {
      width: 300,
      marginLeft: "35px",
    },
  },
  servicestext: {
    fontFamily: "poppins",
    [theme.breakpoints.up("md")]: {
      marginBottom: "10px",
      fontSize: "30px",
      fontWeight: 600,
      color: "#222222",
      lineHeight: "38px",
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      lineHeight: "32px",
      fontSize: "24px",
      fontWeight: 600,
      color: "#111029",
      margin: "20px",
    },
  },
  servicespara: {
    fontFamily: "poppins",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#656161",
      lineHeight: "24px",
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: 600,
      color: "#656161",
      margin: "20px",
    },
  },
  lastDiv: {
    [theme.breakpoints.up("md")]: {
      height: "600px",
      marginTop: "50px",
      backgroundImage: `url("images/Polygon.svg")`,
      backgroundAttachment: "cover",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% auto",
      resizeMode: "contain",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      height: "500px",
      backgroundImage: `url("images/Polygon2.svg")`,
      backgroundAttachment: "cover",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% auto",
      resizeMode: "contain",
    },
  },
  text4: {
    fontFamily: "poppins",
    marginTop: "40px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "10px",
      fontSize: "42px",
      fontWeight: 600,
      color: "white",
      lineHeight: "50px",
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "80px",
      textAlign: "left",
      lineHeight: "32px",
      fontSize: "24px",
      fontWeight: 600,
      color: "white",
      margin: "20px",
    },
  },
  kamari: {
    fontFamily: "poppins",
    marginBottom: "40px",
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      fontWeight: 400,
      color: "#F8F8F9",
      lineHeight: "42px",
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: 600,
      color: "white",
      margin: "20px",
    },
  },
  text5: {
    fontFamily: "poppins",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      fontWeight: 400,
      color: "white",
      lineHeight: "24px",
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: 600,
      color: "white",
      margin: "20px",
    },
  },
  text6: {
    fontFamily: "poppins",

    [theme.breakpoints.up("md")]: {
      marginBottom: "10px",
      fontSize: "32px",
      fontWeight: 600,
      color: "white",
      lineHeight: "42px",
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      lineHeight: "32px",
      fontSize: "24px",
      fontWeight: 600,
      color: "white",
      margin: "20px",
    },
  },
  imagelastdiv: {
    [theme.breakpoints.up("md")]: {
      marginTop: "167px",
      marginLeft: "70px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  community: {
    [theme.breakpoints.up("md")]: {
      height: "500px",
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      height: "300px",
      marginLeft: "75px",
    },
  },
  text10: {
    fontFamily: "poppins",
    marginTop: "40px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "10px",
      fontSize: "42px",
      fontWeight: 600,
      color: "#111029",
      lineHeight: "56px",
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "80px",
      textAlign: "center",
      lineHeight: "32px",
      fontSize: "24px",
      fontWeight: 600,
      color: "#111029",
      margin: "20px",
    },
  },
  
}));
export { useStyles };
