import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import "../../index.css";
import { useStyles } from "./styles";
import { propTypes } from "react-bootstrap/esm/Image";

const Footer = (props) => {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "#3C4564", marginTop: 100 }}>
      <Grid container className={classes.footertext}>
        <Grid item xs={12} md={3} className={classes.col}>
          <Typography>
            <b className={classes.footerContent}>
              About us
            </b>
          </Typography>
          <Typography className={classes.footerText}>
            <b>Bolti Kitabain</b> is a web/app based audio listening platform
            where you can enjoy listening to your favorite books according to
            your convenience.
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={3} className={classes.col}>
          <Typography>
            <b className={classes.footerContent}>Contact us</b>
          </Typography>
          <Typography>
            <b className={classes.footerText}>Telephone: </b> +(92)-42-35880062
            <br></br>
            <b className={classes.footerText}>Fax: </b> +(92)-42-99232123
            <br></br>
            <b className={classes.footerText}>E-mail: </b> boltikitabainpitb@gmail.com
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={2} className={classes.col}>
          <Typography>
            <b style={{ color: "#759FBC", fontSize: " 20px" }}>Find us @</b>
          </Typography>
          <div style={{ marginLeft: "-16px" }}>
            <img className={classes.icons} src="images/fb.png" alt="FB logo" />
            <img
              className={classes.icons}
              src="images/insta.png"
              alt="Instagram logo"
            />
            <img
              className={classes.iconTwitter}
              src="images/twitter.png"
              alt="Twitter logo"
            />
          </div>

          <Link to="privacyPolicy" style={{ textDecoration: 'none' }}>
            <a className={classes.privacyPolicy}>Privacy Policy</a>
          </Link>
        </Grid>
        <Grid align="start" item xs={12} md={4} className={classes.disclaimer}>
          <small className={classes.footerTextDisclaimer}>
            Disclaimer : The content available on this web app has been
            collected through various open media platforms and through
            contributions made by generous users. If anyone reports a copy right
            claim, that content will be removed immediately.
          </small>
        </Grid>
      </Grid>
      <Grid container>
        <Grid items xs={12} md={12} className={classes.copyright}>
          <small className={classes.copyrightText}>
            BoltiKitabain - Copyright © by Punjab Information Technology Board
          </small>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Footer);
