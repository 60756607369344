import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  mainGrid: {
    borderRadius: "5px",
    marginTop: "30px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  },
  curvedDiv: {
    backgroundColor: "#FFB001",
  },
  curvedDivsvg: {
    display: "block",
  },
  curvedDivupper: {
    backgroundColor: "#fff",
  },
  contributeImage: {
    [theme.breakpoints.up("md")]: {
      width: "500px",
      marginLeft: "90px",
      marginTop: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
export { useStyles };
