import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import DropDownMenu from "./DropDownMenu";
import userService from "../../services/UserService";

import { useStyles } from "./styles";

import Auth from "../Auth/Auth";

// import { ReactComponent as Logo } from "../../assets/logo/LOGO.svg";

const MobileView = (props) => {
  const [checked, setChecked] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setChecked((prev) => !prev);
  };
  const classes = useStyles();
  return (
   
      <Grid container>
        <Grid item xs={3}>
          <div className="menu-wrap">
            <input
              checked={checked}
              onChange={() => setChecked((prev) => !prev)}
              type="checkbox"
              className="toggler"
            />
            <div className="hamburger">
              <div></div>
            </div>
            <div className="menu">
              <div>
                {userService.isLoggedIn() ? 
                <div>
                <ul>
                  <li
                    onClick={() => {
                      setChecked((prev) => !prev);
                      props.history.push("/");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <a class="dropbtn">Home</a>
                  </li>
                  <li
                    onClick={() => {
                      setChecked((prev) => !prev);
                      props.history.push("/audiobooks");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <a class="dropbtn">Audio Books</a>
                  </li>
               
                  <li
                    onClick={() => {
                      setChecked((prev) => !prev);
                      props.history.push("/contribute");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <a class="dropbtn">Contribute</a>
                  </li>

                  <li
                    onClick={() => {
                      setChecked((prev) => !prev);
                      props.history.push("/myaudiobooks");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <a class="dropbtn">My Audio Books</a>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        props.history.push("/signin");
                        userService.logout();
                      }}
                    >
                      <div class="dropdown">
                        <a class="dropbtn">Logout</a>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>:<div>
                <ul>
                <li
                    onClick={() => {
                      setChecked((prev) => !prev);
                      props.history.push("/signin");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <a class="dropbtn">Login</a>
                  </li>
                  <li
                    onClick={() => {
                      setChecked((prev) => !prev);
                      props.history.push("/signup");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <a class="dropbtn">Register</a>
                  </li>
                </ul>
                </div>}
                
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

  );
};

export default withRouter(MobileView);
