import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import useStyles from "./styles";
import userService from "../../services/UserService";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { toast } from "react-toastify";

import { Grid } from "@material-ui/core";

const SignIn = (props) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const googleSuccess = async (res) => {
    console.log(res);
    const result = res?.profileObj;
    const token = res?.tokenId;

    userService
      .login_google(result, token)
      .then(() => {
        props.history.push("/");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });

    try {
    } catch (err) {
      console.log(err);
    }
  };
  const googleFailure = (err) => {
    console.log(err);
    console.log("google sign in was unsuccessful");
  };

  const responseFacebook = (res) => {
    console.log(res);

    const email = res?.email;
    const first_name = res?.first_name;
    const last_name = res?.last_name;
    const token = res?.accessToken;
    if (email && first_name && last_name && token !== undefined) {
      userService
        .login_facebook(email, first_name, last_name, token)
        .then(() => {
          props.history.push("/");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });

      try {
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <Grid container className={classes.container}>
      <Grid item md={7}>
        <img
          className={classes.loginImage}
          style={{ width: "400px" }}
          src="images/login.png"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Typography
              component="h1"
              variant="h6"
              style={{ fontFamily: "poppins", marginBottom: "15px" }}
            >
              <b>Welcome Back :)</b>
            </Typography>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </FormControl>

            <Button
              onClick={() => {
                userService
                  .login(email, password)
                  .then(() => {
                    props.history.push("/");
                    window.location.reload();
                  })
                  .catch((err) => {
                    toast.error(err.response.data, {
                      position: toast.POSITION.TOP_CENTER,
                    });
                  });
              }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign in
            </Button>
            <Grid container spacing={1}>
              <Grid className={classes.fb_google} item xs={12} md={6}>
                <FacebookLogin
                  style={{ backgroundColor: "#1877F2" }}
                  textButton="FACEBOOK SIGN IN"
                  appId="650817936304790"
                  cssClass="facebookStyle"
                  fields="first_name,last_name,email,picture"
                  autoLoad={false}
                  callback={responseFacebook}
                />
              </Grid>
              <Grid className={classes.fb_google} item xs={12} md={6}>
                <GoogleLogin
                  clientId="1029234549696-u94aftst83c81krdononasusloa9c044.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <Button
                      style={{ marginTop: 10, backgroundColor: "#D70000" }}
                      className={classes.googleButton}
                      color="primary"
                      fullWidth
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      variant="contained"
                    >
                      Google Sign In
                    </Button>
                  )}
                  onSuccess={googleSuccess}
                  onFailure={googleFailure}
                  cookiePolicy="single_host_origin"
                />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: "15px" }}>
              <small>
                Don't have an account ?
                <Link
                  className={classes.link}
                  component={RouterLink}
                  to="signup"
                >
                  <i> Sign Up</i>
                </Link>
              </small>
            </Typography>
          </Paper>
        </main>
      </Grid>
    </Grid>
  );
};
export default SignIn;
