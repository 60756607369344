import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import Popularbooks from "../PopularBooksSwiper/PopularBooks";
import "../../index.js";
import "../../index.css";
import { auto } from "@popperjs/core";
import { useStyles } from "./styles";
import Auth from "../Auth/Auth";
import Aos from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import audiobooks from "../AudioBook/AudioBook";
import { Opacity } from "@material-ui/icons";
import bookService from "../../services/BookService";

const LandingPage = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    bookService
      .testData()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }, []);
  const classes = useStyles();

  return (
    <div>
      <div className={classes.mainDiv}>
        <div className={classes.root}>
          <Grid container>
            <Grid className={classes.text1} item xs={12} md={7}>
              <Grid className={classes.imageGrid} item md={5}>
                <div data-aos="fade-left" data-aos-duration="2000">
                  <img
                    className={classes.landingimage2}
                    src="images/bkTitle.png"
                  />
                </div>
              </Grid>

              <Typography
                data-aos="flip-down"
                data-aos-duration="1500"
                paragraph
                component="div"
                className={classes.powered}
              >
                <i>Powered by PITB</i>
              </Typography>
              {/* <Grid item xs={8}> */}
              <Typography
                paragraph
                component="div"
                className={classes.fontname2}
              >
                Bolti Kitabain is a web/app based audio listening platform where
                you can enjoy listening to your favorite books according to your
                convenience.
              </Typography>
              {/* </Grid> */}

              <Button className={classes.getstarted} variant="contained">
                <Link
                  style={{
                    textDecoration: "none",
                  color: "#F5AE31",
                    fontSize: 16,
                    fontWeight: "bold",
                    fontFamily: "poppins",
                  }}
                  to="/audiobooks"
                >
                  Get Started
                </Link>
              </Button>
            </Grid>
            <Grid className={classes.imageGrid} item md={5}>
              <div data-aos="fade-left" data-aos-duration="2000">
                <img
                  className={classes.landingimage}
                  src="images/landingPage.png"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* <div style={{ marginTop: "100px" }}>
        <Grid conntainer>
          <Grid item xs={12} md={12}>
            <Typography
              data-aos="fade-up"
              data-aos-duration="1500"
              className={classes.ourpurpose}
            >
              Our Purpose
            </Typography>
          </Grid>
        </Grid>
      </div> */}
      {/* <div
        style={{
          marginTop: "60px",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={4}>
            <motion.div
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.2 },
              }}
            >
              <img
                data-aos="zoom-in"
                data-aos-duration="1000"
                className={classes.servicesimage}
                src="images/easierAccess.png"
              />
            </motion.div>
            <Typography
              data-aos="fade-up"
              data-aos-duration="1700"
              className={classes.servicestext}
            >
              To Educate
            </Typography>
            <Typography
              data-aos="fade-up"
              data-aos-duration="1900"
              className={classes.servicespara}
            >
              Easy Access to excellent Audio Books.
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <motion.div
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.2 },
              }}
            >
              <img
                data-aos="zoom-in"
                data-aos-duration="1000"
                className={classes.servicesimage}
                src="images/s1.png"
                style={{ marginTop: "15px", marginBottom: "10px" }}
              />
            </motion.div>
            <Typography
              data-aos="fade-up"
              data-aos-duration="1700"
              className={classes.servicestext}
            >
              To Share Knowledge
            </Typography>
            <Typography
              data-aos="fade-up"
              data-aos-duration="1900"
              className={classes.servicespara}
            >
              Contribute your books To Bolti Kitabain
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <motion.div
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.2 },
              }}
            >
              <img
                data-aos="zoom-in"
                data-aos-duration="1000"
                className={classes.servicesimage}
                src="images/listen.png"
              />
            </motion.div>
            <Typography
              data-aos="fade-up"
              data-aos-duration="1700"
              className={classes.servicestext}
            >
              Easy Access
            </Typography>
            <Typography
              data-aos="fade-up"
              data-aos-duration="1900"
              className={classes.servicespara}
            >
              Find awesome Audio Books at one place
            </Typography>
          </Grid>
        </Grid>
      </div> */}

      <Popularbooks />
      {/* <div className={classes.lastDiv}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              data-aos="fade-up"
              data-aos-duration="1700"
              className={classes.text4}
            >
              Knowledge Increases <br /> By Sharing <br /> not By Saving.
            </Typography>
            <Typography
              data-aos="fade-up"
              data-aos-duration="1900"
              className={classes.kamari}
            >
              -Kamari
            </Typography>
            <Typography
              data-aos="fade-up"
              data-aos-duration="2000"
              className={classes.text5}
            >
              Let's take a responsibility and try to share knowledge as <br />{" "}
              much as we can. Spread the word, help people know about <br />{" "}
            </Typography>
            <Typography
              data-aos="fade-up"
              data-aos-duration="2000"
              className={classes.text6}
            >
              <i>Bolti Kitabain</i>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              data-aos="fade-up"
              data-aos-duration="2500"
              className={classes.imagelastdiv}
              src="images/share.png"
            />
          </Grid>
        </Grid>
      </div> */}
      {/* <div style={{ marginTop: "50px", marginBottom: "50px" }}>
        <Grid container>
          <Grid md={2}></Grid>
          <Grid
            data-aos="fade-right"
            data-aos-duration="2500"
            item
            xs={12}
            md={4}
          >
            <img className={classes.community} src="images/community.png" />
          </Grid>
          <Grid
            data-aos="fade-down"
            data-aos-duration="2500"
            item
            xs={12}
            md={4}
          >
            <Typography className={classes.text10}>
              For Any Help or Query <br /> send an email at <br /> <br />
              <i>
                boltikitabainpitb
                <br />
                @gmail.com
              </i>
            </Typography>
          </Grid>
          <Grid md={2}></Grid>
        </Grid>
      </div> */}
    </div>
  );
};
export default LandingPage;
