import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Box,
  Container,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import bookService from "../../services/BookService";
import categoryService from "../../services/CategoryService";
import subCategoryService from "../../services/SubCategoryService";
import genreService from "../../services/GenreService";
import ShowMoreText from "react-show-more-text";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Loader from "../Loader/Loader";

import "../../index.css";
import { useStyles } from "./styles";

const AudioBook = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState(null);
  const [searchedVal, setSearchedVal] = useState("");
  const [parentVal, setParentVal] = useState("");
  const [subVal, setSubVal] = useState("");
  const [genreVal, setGenreVal] = useState("");
  const [books, setBooks] = useState([]);
  const [booksData, setBooksData] = useState([]);
  const [genreData, setGenreData] = useState([]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  useEffect(() => {
    bookService
    .getBook()
    .then((data) => {
      setBooks(data);
      setBooksData(data);
    })
    .catch((err) => console.log(err));
    
    categoryService
    .getCategory()
    .then((data) => {

        setCategories(data);
      })
      .catch((err) => console.log(err));

    genreService
      .getGenreByParent(subVal)
      .then((data) => {

        console.log(data)
        setGenreData(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    subCategoryService
      .getSubCategoryByParent(parentVal)
      .then((data) => {
        console.log(data);
        setSubCategories(data);
      })
      .catch((err) => console.log(err));
  }, [parentVal]);
  useEffect(() => {
    genreService
      .getGenreByParent(subVal)
      .then((data) => {
        console.log('Genre data ', data);
        setGenreData(data);
      })
      .catch((err) => console.log(err));
  }, [subVal]);
  const classes = useStyles();

  const callFuncSearched = (val) => {
    console.log(val);
    setSearchedVal(val);
  };

  const callFuncLevel1 = (val) => {
    console.log(val.name);
    setParentVal(val.name);
  };

  const callFuncLevel2 = (val) => {
    console.log(val.name);
    setSubVal(val.name);
  };

  const callFuncGenre = (val) => {
    console.log(val.name);
    setGenreVal(val.name);
  };

  const callSetter = () => {
    setParentVal("");
    setSubVal("");
  };

  const searchFunc = () => {
    setSearchedVal("");
    setBooks(booksData);
  };
  return (
    <Container maxWidth="lg">
        <Grid container>
          <Grid sm={12} md={5} xs={5} item className={classes.imageGrid}>
             <img src="images/i2.png" width="200" height="200" className={classes.center}/>   
          </Grid>
         
          <Grid container>
            <Grid sm={8}>
              <Typography className={classes.searchtitle}>
                <h3 style={{ color: "grey" }}>Search Here</h3>
              </Typography>
              <Autocomplete
                className={classes.searchBar}
                id="combo-box-demo"
                onChange={(event, value) => {
                  value ? callFuncSearched(value) : searchFunc();
                }}
                options={booksData.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Book"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            </Grid>  
            <Grid sm={2}>
              <Button
                className={classes.searchbutton}
                variant="contained"
                color="primary"
                onClick={() => {
                  bookService.getSearchedBook(searchedVal).then((data) => {
                    setBooks(data);
                  });
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      <Grid align="center" container>
        {/* level 1 category */}

        <Grid className={classes.dd2} item xs={12} lg={3}>
          <Autocomplete
            onChange={(event, value) => {
              value ? callFuncLevel1(value) : callSetter();
            }}
            id="combo-box-demo"
            options={categories ? categories : ""}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Category"
                variant="outlined"
              />
            )}
          />
        </Grid>

        {/* level 2 category */}

        <Grid className={classes.dd1} item xs={12} lg={3}>
          <Autocomplete
            id="combo-box-demo"
            onChange={(event, value) => {
              value ? callFuncLevel2(value) : setSubVal("");
            }}
            options={subCategories}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Sub-Category"
                variant="outlined"
              />
            )}
          />
        </Grid>

        {/* genre */}

        <Grid className={classes.dd1} item xs={12} lg={3}>
          <Autocomplete
            id="combo-box-demo"
            options={genreData}
            onChange={(event, value) => {
              value ? callFuncGenre(value) : setGenreVal("");
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Select Genre" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={() => {
              bookService
                .getFilteredBooks(parentVal, subVal, genreVal)
                .then((data) => {
                  setBooks(data);
                });
            }}
          >
            Apply Filter
          </Button>
        </Grid>
      </Grid>
      <Typography className={classes.allbooks}>
        <h3 style={{ color: "grey" }}>All Books</h3>
      </Typography>
      {books.length == 0 ? (
        <>
          <Loader type={"Audio"} />
        </>
      ) : (
        <Grid container style={{ marginTop: "30px" }}>
          {books?.map((book, index) => {
            return (
              <Grid key={book._id} align="center" item xs={12} lg={3}>
                <Card  className={classes.root}>
                  <CardActionArea
                    onClick={() =>
                      props.history.push(`/singleBook/${book._id}`)
                    }
                  >
                    <CardMedia
                      className={classes.media}
                      image={`https://boltikitabain.pk:8443/images/${book.image}`}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        <ShowMoreText
                          lines={1}
                          more={<span style={{ display: "none" }}>.</span>}
                          truncatedEndingComponent={"..."}
                        >
                          {book.title}
                        </ShowMoreText>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        <ShowMoreText
                          lines={4}
                          more={<span style={{ display: "none" }}>.</span>}
                          truncatedEndingComponent={"..."}
                        >
                          {book.description}
                        </ShowMoreText>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions style={{ justifyContent: "center" }}>
                    <TwitterShareButton
                      url={`https://boltikitabain.pk/singleBook/${book._id}`}
                      title={"Bolti Kitabain"}
                      className="Demo__some-network__share-button"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <FacebookShareButton
                      url={`https://boltikitabain.pk/singleBook/${book._id}`}
                      quote={
                        "Hello People Check Out This audio book on boltikitabain.pk"
                      }
                      hashtag={"#boltikitabain"}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={`https://boltikitabain.pk/singleBook/${book._id}`}
                      title={
                        "Hey check out this amazing audio book that I found on boltikitabain.pk"
                      }
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <TelegramShareButton
                      url={`https://boltikitabain.pk/singleBook/${book._id}`}
                      title={
                        "Hey check out this amazing audio book that I found on boltikitabain.pk"
                      }
                      className="Demo__some-network__share-button"
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Container>
  );
};

export default withRouter(AudioBook);
