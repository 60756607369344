import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import userService from "../../services/UserService";
import Chip from "@material-ui/core/Chip";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { withRouter } from "react-router";
// import userService from "../../services/UserService";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({


  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    overflowY: "auto",
    scrollbarWidth: 'thin',
          scrollbarColor: '#B7B7B7 transparent',
          '&::-webkit-scrollbar': {
            width: 6,
            height: 6,
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 6,
            backgroundColor: '#B7B7B7',
            minHeight: 24,
            minWidth: 24,
          },
          '&::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#adadad',
          },
          '&::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#adadad',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#adadad',
          },
          '&::-webkit-scrollbar-corner': {
            backgroundColor: 'transparent',
          },
  },
}));

const Chapters = (props) => {
  const { chapters } = props;
  console.log(props);
  const classes = useStyles();

  return (
    <List
      style={{ maxHeight: 300, overflow: "auto" }}
      component="nav"
      className={classes.root}
      aria-label="mailbox folders"
    >
      {chapters.map((chapter, index) => {
        if (
          chapter.approved == true ||
          chapter.user_id == userService.getLoggedInUser()._id
        ) {
          return (
            <div key={index}>
              <ListItem
                button
                onClick={(e) => {
                  // userService.isLoggedIn()
                  //   ? props.history.push("/chapter/" + chapter._id)
                  //   : toast.error("You Need To Log In To Read This Chapter!", {
                  //       position: toast.POSITION.TOP_CENTER,
                  //     });
                  props.history.push("/audioplayer/" + chapter._id);
                }}
              >
                <ListItemText
                  primary={chapter.title}
                  secondary={chapter.titleUrdu}
                />

                <Chip
                  icon={<PlayCircleOutlineIcon />}
                  size="small"
                  label={chapter.approved ? "Listen Now" : "pending approval"}
                  color={chapter.approved ? "primary" : "secondary"}
                />
              </ListItem>
              <Divider />
            </div>
          );
        }
      })}
    </List>
  );
};

export default withRouter(Chapters);
