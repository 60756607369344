import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ShowMoreText from "react-show-more-text";

import Auth from "../Auth/Auth";
import bookService from "../../services/BookService";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "10px",
  },
  media: {
    height: 200,
  },
});
const MyAudioBook = (props) => {
  const [stories, setStories] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    bookService
      .getAudioBooks()
      .then((data) => {
        console.log(data[0].image);
        setStories(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(stories);
  return (
    <Auth>
      <div>
        <Container maxWidth="lg" style={{ marginTop: "50px" }}>
          <Grid container spacing={3}>
            {stories.length != 0 ? (
              <>
                {stories.map((story, index) => {
                  console.log(story.image);
                  return (
                    <Grid item xs={12} md={4} key={index}>
                      <Card
                        className={classes.root}
                        onClick={(e) => {
                          props.history.push("/singleBook/" + story._id);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            image={`https://boltikitabain.pk:8443/images/${story.image}`}
                            title="AudioBook"
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              <ShowMoreText
                                lines={1}
                                more={
                                  <span style={{ display: "none" }}>.</span>
                                }
                                truncatedEndingComponent={"..."}
                              >
                                {story.title}
                              </ShowMoreText>
                            </Typography>
                            <Chip
                              size="small"
                              label={
                                story.approved
                                  ? "Approved"
                                  : story.disapproved == false
                                  ? "pending approval"
                                  : "Not Approved"
                              }
                              color={story.approved ? "primary" : "secondary"}
                            />
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                <strong>You have not written any story yet</strong>
              </p>
            )}
          </Grid>
        </Container>
      </div>
    </Auth>
  );
};

export default withRouter(MyAudioBook);
