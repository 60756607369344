import {
  Button,
  Divider,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import novelService from "../../services/NovelService";
// import userService from "../../services/UserService";
import chapterService from "../../services/ChapterService";
import Chapters from "../Chapters/Chapters";
// import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import libraryService from "../services/LibraryService";
import { toast } from "react-toastify";
// import { FaWindows } from "react-icons/fa";
import Auth from "../Auth/Auth";
import bookService from "../../services/BookService";
import userService from "../../services/UserService";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
const SingleBook = (props) => {
  const [novel, setNovel] = useState([]);
  //   const [user_info, setUserInfo] = useState([]);
  const [chapters, setChapters] = useState([]);
  //   const [library, setLibrary] = useState([]);
  //   const [loader, setLoader] = useState(true);
  //   const [trigger, setTrigger] = useState(false);
  //   const [trigger2, setTrigger2] = useState(false);

  // const [existing, setExisting] = useState([]);

  const id = props.match.params.id;

  useEffect(() => {
    bookService
      .getSingleBook(id)
      .then((data) => {
        console.log(data);
        setNovel(data);
        // setUserInfo(data.user_info);
        // setChapters(data.chapters);
        // setLibrary(data.library);
        // setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    chapterService
      .getChapter(id)
      .then((data) => {
        setChapters(data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <Auth>
      <div>
        <Grid container style={{ marginTop: "30px" }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10} md={10} lg={1}>
            <img
              src={`https://boltikitabain.pk:8443/images/${novel.image}`}
              className="image3"
              width="300px"
              height="400px"
              alt=""
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10} lg={7}>
            <br />
            <Typography variant="h5" style={{ fontFamily: "poppins" }}>
              Title: <b>{novel?.title}</b>
            </Typography>{" "}
            {novel.titleUrdu != "" && (
              <>
                <br />

                <Typography variant="h5" style={{ fontFamily: "poppins" }}>
                  Title (Urdu): <b>{novel?.titleUrdu}</b>
                </Typography>
              </>
            )}
            {novel.titleUrdu != "" && (
              <>
                <br />

                <Typography variant="h5" style={{ fontFamily: "poppins" }}>
                  Category: <b>{novel?.categories}</b>
                </Typography>
              </>
            )}
            <br />
            {novel?.genre != "" && (
              <>
                <Typography variant="h5" style={{ fontFamily: "poppins" }}>
                  Genre:{" "}
                  <b>
                    {novel.genre?.map((item, index) => {
                      if (index != novel?.genre.length - 1) {
                        return item + ", ";
                      } else {
                        return item;
                      }
                    })}
                  </b>
                </Typography>
              </>
            )}
            <br />
            {/* <br />
            Written by <strong>{user_info.name}</strong> */}
            <br />
            <TwitterShareButton
              style={{ marginRight: 5 }}
              url={`https://boltikitabain.pk/singleBook/${novel?._id}`}
              title={"Bolti Kitabain"}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <FacebookShareButton
              style={{ marginRight: 5 }}
              url={`https://boltikitabain.pk/singleBook/${novel?._id}`}
              quote={
                "Hello People Check Out This audio book on boltikitabain.pk"
              }
              hashtag={"#boltikitabain"}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <WhatsappShareButton
              style={{ marginRight: 5 }}
              url={`https://boltikitabain.pk/singleBook/${novel?._id}`}
              title={
                "Hey check out this amazing audio book that I found on boltikitabain.pk"
              }
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <TelegramShareButton
              url={`https://boltikitabain.pk/singleBook/${novel?._id}`}
              title={
                "Hey check out this amazing audio book that I found on boltikitabain.pk"
              }
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <br />
            <hr style={{ marginTop: "30px" }} />
            <br />
            <br />
            <Typography variant="h5">Theme of the Book</Typography>
            <Typography
              variant="body2"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              {novel?.description}
            </Typography>
          </Grid>
        </Grid>
        {/* {userService.isLoggedIn() && ( */}
        <Grid container>
          <Grid item xs={1}></Grid>

          <Grid item xs={11} md={5}>
            {!chapters.length == 0 && (
              <Button
                style={{
                  marginTop: "30px",
                  marginRight: "20px",
                  backgroundColor: "#3C4564",
                  color: "white",
                  padding: "10px",
                  width: "150px",
                }}
                variant="contained"
                size="small"
                onClick={(e) => {
                  console.log(chapters[0]?._id);
                  chapterService
                    .getSingleChapter(chapters[0]?._id)
                    .then((data) => {
                      console.log(data._id);
                      props.history.push("/audioplayer/" + data?._id);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Listen Now
              </Button>
            )}
            {/* {!library.length == 0 ? (
              <Button
                style={{ marginTop: "30px" }}
                variant="contained"
                color="secondary"
                size="small"
                onClick={(e) => {
                  libraryService
                    .deleteLibrary(novel._id)
                    .then((data) => {
                      console.log(data);
                      toast.info("Removed From Library", {
                        position: toast.POSITION.TOP_CENTER,
                      });
                      window.location.reload();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  setTrigger(false);
                }}
              >
                Remove From Library
              </Button>
            ) : (
              <Button
                style={{ marginTop: "30px" }}
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => {
                  console.log(novel._id);
                  libraryService
                    .addLibrary(novel)
                    .then((data) => {
                      console.log(data);
                      toast.info("Added To Library", {
                        position: toast.POSITION.TOP_CENTER,
                      });
                      window.location.reload();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  setTrigger(true);
                }}
              >
                Add To Library
              </Button>
            )} */}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} md={5}>
            {novel.user_id == userService.getLoggedInUser()?._id && (
              <div>
                <Button
                  size="small"
                  style={{
                    marginTop: "30px",
                    backgroundColor: "goldenrod",
                    color: "white",
                    marginRight: "10px",
                  }}
                  variant="contained"
                  onClick={(e) => {
                    props.history.push("/editAudioBook/" + novel?._id);
                  }}
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  style={{
                    marginTop: "30px",
                    backgroundColor: "indianred",
                    color: "white",
                    marginRight: "10px",
                  }}
                  variant="contained"
                  onClick={(e) => {
                    if (window.confirm("Press Ok to confirm deletion")) {
                      bookService
                        .deleteBook(novel?._id)
                        .then(() => {
                          toast.info("deleted Successfully", {
                            position: toast.POSITION.TOP_CENTER,
                          });
                          props.history.push("/");
                        })
                        .catch((err) => {
                          toast.error(err?.response.data, {
                            position: toast.POSITION.TOP_CENTER,
                          });
                        });
                    } else {
                      // no is clicked
                    }
                  }}
                >
                  Delete
                </Button>
                <Button
                  size="small"
                  style={{
                    marginTop: "30px",
                    backgroundColor: "mediumvioletred",
                    color: "white",
                    marginRight: "10px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.history.push("/addchapter/" + novel?._id);
                  }}
                >
                  New Chapter
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
        {/* )} */}
        <Divider style={{ marginTop: "50px", marginBottom: "20px" }} />
        {!chapters.length == 0 ? (
          <div>
            <Grid container>
              <Grid item xs={12}>
                <h2 style={{ textAlign: "center", marginBottom: 30 }}>
                  TABLE OF CONTENTS
                </h2>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>

            <Grid align="center" container>
              <Grid item xs={12}>
                <Chapters chapters={chapters} />
              </Grid>
            </Grid>
          </div>
        ) : (
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginTop: "40px",
            }}
          >
            <strong>This story does not have any chapters yet!</strong>
          </p>
        )}
      </div>
    </Auth>
  );
};

export default SingleBook;
