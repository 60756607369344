import React from "react";
import userService from "../../services/UserService";
import { withRouter } from "react-router";

const Auth = (props) => {
  React.useEffect(() => {
    console.log(userService.isLoggedIn());
    if (!userService.isLoggedIn()) {
      props.history.push("/signin");
    }
  }, []);
  return <>{props.children}</>;
};

export default withRouter(Auth);
