import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  footertext: {
    padding: theme.spacing(2),
    color: "white",
    fontFamily: "Segeo UI",
    textAlign: "justify",
    borderBottom: "1px solid #90c3c8",
    [theme.breakpoints.down('sm')]:{
      textAlign:"center",
    }
  },
  icons: {
    height: "25px",
    padding: "5px",
  },
  iconTwitter: {
    height: "25px",
    padding: "5px",
    marginLeft: "8px",
  },
  col: {
    marginTop: "30px",
  },
  copyright: {
    fontSize: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    color: "#B4B4B4",
    textAlign: "center",
  },
  disclaimer: {
    fontSize: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    color: "#B4B4B4",
  },
  footerText:{
     
    fontSize: "14px",
    fontFamily: "poppins",
    marginTop:"5px",
  },
  footerContent:{
    color: "#759FBC", 
    fontSize: " 20px",
    fontFamily: "poppins",
  },
  footerTextDisclaimer:{
    fontSize: "10px",
    fontFamily: "poppins",
  },
  copyrightText:{
    fontSize: "10px",
    fontFamily: "poppins",
  },
  privacyPolicy:{
    fontSize: "14px",
    fontFamily: "poppins",
    color:"white",
    marginTop:"5px",
  },
}));
export { useStyles };
