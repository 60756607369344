import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: "#FB923A",
  },
  fb_google: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  container: {
    backgroundImage: `url("images/line.svg")`,
    backgroundAttachment: "cover",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    resizeMode: "contain",
  },
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("md")]: { marginTop: "120px", marginBottom: "100px" },
    [theme.breakpoints.down("md")]: {
      marginTop: "150px",
      marginBottom: "120px",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: "#3C4564",
    marginBottom: "10px",
  },
  link: {
    marginTop: theme.spacing.unit * 2,
  },
  loginImage: {
    [theme.breakpoints.up("md")]: {
      marginTop: "120px",
      marginBottom: "100px",
      marginLeft: "200px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
export default useStyles;
