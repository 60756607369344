import jwtDecode from "jwt-decode";
import GenericService from "./GenericService";

class UserService extends GenericService {
  constructor() {
    super();
  }

  login = (email, password) =>
    new Promise((resolve, reject) => {
      this.post("/api/users/login", { email, password })
        .then((data) => {
          localStorage.setItem("token", data.token);
          resolve(data.token);
          
        })
        .catch((err) => {
          reject(err);
        });
    });
  check_login = () =>
    new Promise((resolve, reject) => {
      this.post("/api/users/check_login")
        .then((data) => {})
        .catch((err) => {
          reject(err);
        });
    });
  login_google = (result, token) =>
    new Promise((resolve, reject) => {
      this.post("/api/users/login_google", { result, token })
        .then((token) => {
          localStorage.setItem("token", token);
          resolve(token);
          window.location.reload();

        })
        .catch((err) => {
          reject(err);
        });
    });
  login_facebook = (email, first_name, last_name, token) =>
    new Promise((resolve, reject) => {
      this.post("/api/users/login_facebook", {
        email,
        first_name,
        last_name,
        token,
      })
        .then((token) => {
          localStorage.setItem("token", token);
          resolve(token);
          window.location.reload();

        })
        .catch((err) => {
          reject(err);
        });
    });

  register = (first_name, last_name, email, password) =>
    this.post("/api/users/register", {
      first_name,
      last_name,
      email,
      password,
    });
  logout = () => {
    localStorage.removeItem("token");
  };

  isLoggedIn = () => {
    return localStorage.getItem("token") ? true : false;
  };

  getLoggedInUser = () => {
    try {
      const jwt = localStorage.getItem("token");
      return jwtDecode(jwt);
    } catch (ex) {
      return null;
    }
  };

  isAdmin = () => {
    if (this.isLoggedIn()) {
      if (this.getLoggedInUser().role == "admin") return true;
      else return false;
    } else return false;
  };
}
let userService = new UserService();
export default userService;
