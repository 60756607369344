import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import chapterService from "../../services/ChapterService";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import bookService from "../../services/BookService";
import { CallMissedSharp } from "@material-ui/icons";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import { useStyles } from "./styles";
import { Container } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import background from "../../assets/images/bk.jpeg";

const AudioPlayerMusic = (props) => {
  // State
  // src={`http://localhost:4000/images/${novel.image}`}
  const classes = useStyles();

  const [title, setTitle] = useState();
  const [bookId, setBookId] = useState("");
  const [bookImage, setBookImage] = useState("");
  const [audioSrc, setAudioSrc] = useState();
  const [previousChapter, setPreviousChapter] = useState([]);
  const [nextChapter, setNextChapter] = useState([]);

  const [chapter_id, setChapterId] = useState(props.match.params.id);

  React.useEffect(() => {
    console.log(chapter_id);
    chapterService
      .getSingleChapter(chapter_id)
      .then((data) => {
        setBookId(data.book_id[0]);
        setTitle(data.title);
        setAudioSrc(data.audio);
        console.log(data.next_chapter);
        if (data.previous_chapter.length !== 0) {
          setPreviousChapter(data.previous_chapter[0]);
        } else {
          setPreviousChapter([]);
        }

        if (data.next_chapter.length !== 0) {
          setNextChapter(data.next_chapter[0]);
        } else {
          setNextChapter([]);
        }
      })
      .catch((err) => console.log(err));
  }, [chapter_id]);

  React.useEffect(() => {
    bookService
      .getSingleBook(bookId)
      .then((data) => {
        setBookImage(data.image);
      })
      .catch((err) => console.log(err));
  }, [bookId]);

  return (
    <div>
      <Grid
        container
        className={classes.bg}
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid item xs={2} lg={3}></Grid>
        <Grid
          align="center"
          style={{ marginBottom: 60, marginTop: 50 }}
          item
          xs={8}
          lg={6}
        >
          <SkipPreviousIcon
            onClick={() => setChapterId(previousChapter)}
            style={
              previousChapter.length !== 0
                ? {
                    fontSize: 50,
                    color: "white",
                    marginRight: 50,
                    cursor: "pointer",
                  }
                : { visibility: "hidden", marginRight: 50, fontSize: 50 }
            }
          />

          {bookImage && (
            <img
              height="328"
              className={classes.image}
              width="100%"
             src={`https://boltikitabain.pk:8443/images/${bookImage}`}
              alt=""
            />
          )}

          <SkipNextIcon
            onClick={() => setChapterId(nextChapter)}
            style={
              nextChapter.length !== 0
                ? {
                    fontSize: 50,
                    color: "white",
                    marginLeft: 50,
                    cursor: "pointer",
                  }
                : { visibility: "hidden", marginLeft: 50, fontSize: 50 }
            }
          />
        </Grid>

        <Grid item xs={2} lg={3}></Grid>

        <Grid item xs={2} lg={2}></Grid>
        <Grid item xs={8} lg={8}>
          <AudioPlayer
            className={classes.player}
            style={{
              backgroundColor: "black",
              border: "1px solid white",
              borderRadius: 10,
              marginBottom: 10,
            }}
            src={`https://boltikitabain.pk:8443/audios/${audioSrc}`}
            onPlay={(e) => console.log("onPlay")}
            // other props here
          />
        </Grid>

        <Grid item xs={2} lg={2}></Grid>
      </Grid>
    </div>
  );
};

export default withRouter(AudioPlayerMusic);
