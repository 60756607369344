import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";

import { withRouter } from "react-router-dom";
import MessageForDisapproval from "./MessageForDisapproval";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginRight: 100,
  },
}));

const NotificationTabs = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [dot_indicator, setDot_indicator] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            onClick={() => setDot_indicator(false)}
            // style={dot_indicator ? { color: "red" } : { color: "#707070" }}
            label="Pending Titles"
            {...a11yProps(0)}
          />

          <Tab label="Approved Titles" {...a11yProps(1)} />

          <Tab label="Unapproved Titles" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {props.stories && props.stories.length != 0
            ? props.stories.map((item) => {
                return (
                  <MenuItem
                    onClick={() => {
                      props.push_the_link(item._id);
                      props.handleClose();
                    }}
                  >
                    <Typography color="textSecondary" gutterBottom>
                      {item.title} is pending for approval
                    </Typography>
                  </MenuItem>
                );
              })
            : "No New Notifications"}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {props.approvedStories &&
            props.approvedStories?.map((item) => {
              return (
                <MenuItem
                  onClick={() => {
                    props.push_the_link(item._id);
                    props.handleClose();
                  }}
                >
                  <Typography color="textSecondary" gutterBottom>
                    {item.title} is approved
                  </Typography>
                </MenuItem>
              );
            })}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {props.disapprovedStories &&
            props.disapprovedStories?.map((item) => {
              return (
                <MenuItem>
                  <Typography color="textSecondary" gutterBottom>
                    <MessageForDisapproval
                      close_dropdown={props.handleClose}
                      item_name={item.title}
                      message={item.disapproval_message}
                    />
                  </Typography>
                </MenuItem>
              );
            })}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default NotificationTabs;
