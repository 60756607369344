import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    bookImage: {

        width:50,
        
    },
    image:{
        
        width:328,
        [theme.breakpoints.down("sm")]:{width:"100%",marginTop:100}
    },
    bg:{
        [theme.breakpoints.down("xl")]: {
        backgroundSize:"100% 100%",
            
          },
      [theme.breakpoints.down("sm")]: {
        backgroundSize:"100% 100%",

        }
    },
    player:{
        [theme.breakpoints.down("sm")]:{marginTop:100,marginBottom:100}}
}));
export { useStyles };
