import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "../../styles.css";
import bookService from "../../services/BookService";
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper/core";
import ShowMoreText from "react-show-more-text";

import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

import { useStyles } from "./styles";

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const PopularBooks = () => {
  const [books, setBooks] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    bookService
      .getBook()
      .then((data) => {
        console.log(data);
        setBooks(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={12}
      >
        <Grid item xs={12} md={4}>
          <Typography variant="h5" className={classes.mpb}>
            Most Popular Books
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={10} md={4}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search Here"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Grid>
        {books.length == 0 ? (
          <>
            <Loader type={"Audio"} />
          </>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={12}
          >
            {books.length != 0 && (
              <Grid item xs={8} md={8}>
                <Swiper
                  style={{ marginTop: 50 }}
                  navigation={true}
                  effect={"coverflow"}
                  centeredSlides={true}
                  slidesPerView={window.innerWidth < 768 ? 1 : "auto"}
                  loop={true}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  className="mySwiper"
                >
                  {books?.map((item, index) => {
                    if (item.approved == true) {
                      return (
                        <SwiperSlide>
                          <Link to={`/singleBook/${item._id}`}>
                            <Typography
                              style={{
                                position: "absolute",
                                width: "60%",
                                textAlign: "center",
                                top:-2,
                                color: "black",
                                fontSize: "15px",
                                fontFamily:"poppins",
                                letterSpacing: "-1px",
                                padding: "10px",
                                background:"white",
                                borderRadius:"7%",
                              }}
                            >
                              <ShowMoreText
                                lines={2}
                                more={
                                  <span style={{ display: "none" }}>.</span>
                                }
                                truncatedEndingComponent={"..."}
                              >
                                {item.title}
                              </ShowMoreText>
                            </Typography>
                            <img
                            className={classes.imageBooks}
                              src={`https://boltikitabain.pk:8443/images/${item.image}`}
                              alt="number 1"
                            />{" "}
                          </Link>
                        </SwiperSlide>
                      );
                    }
                  })}
                </Swiper>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};
export default PopularBooks;
