import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  dd1: {
    [theme.breakpoints.down("xl")]: {
      paddingRight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      paddingRight: 0,
    },
  },
  dd2: {
    [theme.breakpoints.down("xl")]: {
      paddingRight: 20,
      marginLeft: 50,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      marginLeft: 0,

      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  searchBar: {
    marginBottom: 40,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 53,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
    },
  },
  searchtitle: {
    marginTop: 50,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 53,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
    },
  },
  allbooks: {
    marginTop: 50,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 53,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
    },
  },
  searchbutton: {
    marginLeft: 20,
    marginTop: 113,
  },
  root: {
    maxWidth: 260,
    marginBottom: 30,
    height:380
  },
  media: {
    height: 140,
  },
  searchText:{
    color: "grey",
    fontFamily:"poppins",
    fontSize:"18px",
  },
  landingimage: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "14%",
      width: "250px",
      height: "250px", 
    },
  },

  imageGrid:{
    display: "flex",
    justifyContent: "center",
    marginBottom: "27px",
  }
}));
export { useStyles };
