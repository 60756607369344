import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import userService from "../../services/UserService";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withRouter } from "react-router";

const DropDownMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleContribute = () =>{
  //   handleClose();
  //   props.history.push("/contribute");
  // }

  const handleMyAudioBooks = () =>{
    handleClose();
    props.history.push("/myaudiobooks");
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{ marginRight: -30, marginLeft: -15 }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ExpandMoreIcon />
      </Button>
      <Menu
      style={{marginTop:35}}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleContribute}>Contribute</MenuItem> */}
        <MenuItem onClick={handleMyAudioBooks}>My Audio Books</MenuItem>
        <MenuItem
          onClick={() => {
            props.history.push("/signin");
            userService.logout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withRouter(DropDownMenu);
