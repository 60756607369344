import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import "../../index.css";
// import { useStyles } from "./styles";

export default function PrivacyPolicy() {
  //   const classes = useStyles();

  return (
    <div>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid
          item
          xs={12}
          md={12}
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Typography variant="h4">Privacy Policy</Typography>
          <br></br>
          <Typography variant="h5">Introduction</Typography>
          <Typography>
            This is functional mobile app available for public as pilot
            initiative. Available audio contents are: <br></br> • Single
            National curriculum books of PCTB Textbooks <br></br> • Urdu Poetry{" "}
            <br></br> • Literature (Zaviya, Talqeen shah and many other famous
            authors) The concept behind Bolti kitabian is to develop an online
            repository for the audiobooks to facilitate renowned and emerging
            publishers to upload the audio version of their books, novels,
            poetry and stories. The project will be the first mega online
            repository for audiobooks in Pakistan with a millions of resources
            facilitating specially to visually impaired community and general
            public at large.
          </Typography>
          <br></br>
          <Typography variant="h5">
            We Are Commited to Protect Your Privacy
          </Typography>
          <Typography>
            We collect the minimum amount of information about you, commensurate
            with providing you with a satisfactory service. This policy
            indicates the types of processes that may result in data being
            collected about you. Your use of this application gives us the right
            to collect that information and we vow not misuse your personal
            information.
          </Typography>
          <br></br>
          <Typography variant="h5">Information Collected</Typography>
          <Typography>
            We may collect all or any information like PHONE, CAMERA, SMS,
            CALENDAR, CONTACTS, LOCATION, INTERNETNET, SENSOR, STORAGE,
            MICROPHONE, together with data about your use of this application.
            Other information that may be needed time to time to process a
            request may also be collected as indicated on the application.
          </Typography>
          <br></br>
          <Typography variant="h5">Information Use</Typography>
          <Typography>
            We use the information collected primarily to process the task for
            which you visited the application. Data collected in PAKISTAN is
            held in accordance with the Electronic Data Protection Act and The
            Prevention of Electronic Crimes Act. All reasonable precautions are
            taken to prevent unauthorized access to this information.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
