import GenericService from "./GenericService";

class BookService extends GenericService {
  testData = () => this.get("https://amonn.texus.tech/Products")
  getBook = () => this.get("/api/books/");
  getSearchedBook = (searchedVal) =>
    this.post("/api/books/searched", { searchedVal });

    getFilteredBooks = (parentVal,subVal,genreVal) =>
    this.post("/api/books/filtered", { parentVal,subVal,genreVal });

  getSingleBook = (id) => this.get("/api/books/" + id);

  addBook = (formData, config) => this.post("/api/books/", formData, config);

  deleteBook = (_id) => this.delete("/api/books/" + _id);
  getAudioBooks = () => this.get("/api/books/myaudiobooks");
  getAudioBooksUnapproved = () => this.get("/api/books/myaudiobooks_unapproved");
  getAudioBooksDisapproved = () => this.get("/api/books/myaudiobooks_disapproved");
  getAudioBooksApproved = () => this.get("/api/books/myaudiobooks_approved");

  updateBook = (id, formData, config) =>
    this.put("/api/books/" + id, formData, config);
}

let bookService = new BookService();
export default bookService;
