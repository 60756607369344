import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { motion } from "framer-motion";
import CategorySelect from "./CategorySelect";
import ImageInput from "../ImageInput/ImageInput";
import bookService from "../../services/BookService";
import { toast } from "react-toastify";
import categoryService from "../../services/CategoryService";
import subCategoryService from "../../services/SubCategoryService";
import Aos from "aos";
import "aos/dist/aos.css";
import Auth from "../Auth/Auth";
import { useStyles } from "./styles";
const AddAudioBook = (props) => {
  const [title, setTitle] = useState("");
  const [titleUrdu, setTitleUrdu] = useState("");
  const [author, setAuthor] = useState("");
  const [narrator, setNarrator] = useState("");
  const [contributor, setContributor] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImg] = useState(null);
  const [subCategory, setSubCategory] = React.useState("");
  const [genre, setGenre] = React.useState([]);
  const [subCategorySelect, setSubCategorySelect] = React.useState("");
  const [parentCategory, setParentCategory] = React.useState("");
  const [letterCounter, setLettercounter] = useState(500);

  const [categoryData, setCategoryData] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    categoryService
      .getCategory()
      .then((data) => {
        setCategoryData(data);
        console.log(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    subCategoryService
      .getSubCategoryByParent(parentCategory)
      .then((data) => {
        setSubCategory(data);
        console.log(data);
        if (parentCategory != "") {
          setTrigger(true);
        }
      })
      .catch((err) => console.log(err));
  }, [parentCategory]);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const classes = useStyles();
  return (
    <Auth>
      <div className={classes.curvedDivupper}>
        <motion.div
          initial={{ y: -250 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Typography
            style={{
              fontFamily: "poppins",
              textAlign: "center",
              marginTop: "30px",
              marginRight: "10px",
              marginLeft: "10px",
            }}
          >
            <h1>Kudos!</h1>
            <br /> This is <b>your</b> opportunity to build the tomorrow you
            always have wanted, <br />
            <b>Contribute</b> your book to <b>Bolti Kitabain Library</b>
          </Typography>
        </motion.div>

        <svg
          className={classes.curvedDivsvg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 318"
        >
          <path
            fill="#FFB001"
            fill-opacity="1"
            d="M0,128L30,144C60,160,120,192,180,202.7C240,213,300,203,360,192C420,181,480,171,540,176C600,181,660,203,720,186.7C780,171,840,117,900,128C960,139,1020,213,1080,245.3C1140,277,1200,267,1260,240C1320,213,1380,171,1410,149.3L1440,128L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className={classes.curvedDiv}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={6} className={classes.mainGrid}>
              <Grid align="center" container>
                <Grid
                  style={{
                    backgroundColor: "#F6F6F6",
                    borderRadius: "5px",
                    padding: "10px",
                    marginBottom: "5px",
                  }}
                  item
                  xs={12}
                >
                  <h1 style={{ fontFamily: "poppins" }}>Add Audio Book</h1>
                </Grid>
                <Grid
                  align="center"
                  style={{ marginTop: 10 }}
                  item
                  xs={12}
                  lg={6}
                  style={{ backgroundColor: "white" }}
                >
                  <TextField
                    style={{ width: "80%", marginTop: "10px" }}
                    required
                    id="standard-required"
                    label="Book Title English"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>

                <Grid
                  align="center"
                  style={{ marginTop: 10 }}
                  item
                  xs={12}
                  lg={6}
                  style={{ backgroundColor: "white" }}
                >
                  <TextField
                    style={{ width: "80%", marginTop: "10px" }}
                    id="standard-required"
                    label="Book Title Urdu"
                    value={titleUrdu}
                    onChange={(e) => setTitleUrdu(e.target.value)}
                  />
                </Grid>
                <Grid
                  align="center"
                  style={{ marginTop: 10 }}
                  item
                  xs={12}
                  lg={6}
                  style={{ backgroundColor: "white" }}
                >
                  <TextField
                    style={{ width: "80%", marginTop: "10px" }}
                    id="standard-required"
                    label="Narrator"
                    value={narrator}
                    onChange={(e) => setNarrator(e.target.value)}
                  />
                </Grid>
                <Grid
                  align="center"
                  style={{ marginTop: 10 }}
                  item
                  xs={12}
                  lg={6}
                  style={{ backgroundColor: "white" }}
                >
                  <TextField
                    style={{ width: "80%", marginTop: "10px" }}
                    fullWidth
                    id="standard-required"
                    label="Contributor"
                    value={contributor}
                    onChange={(e) => setContributor(e.target.value)}
                  />
                </Grid>
                <Grid
                  align="center"
                  style={{ marginTop: 10 }}
                  item
                  xs={12}
                  lg={6}
                  style={{ backgroundColor: "white" }}
                >
                  <TextField
                    style={{ width: "80%", marginTop: "10px" }}
                    fullWidth
                    id="standard-required"
                    label="Author Name"
                    onChange={(e) => setAuthor(e.target.value)}
                    value={author}
                  />
                </Grid>
                <Grid
                  align="center"
                  style={{ marginTop: 10 }}
                  item
                  xs={12}
                  lg={6}
                  style={{ backgroundColor: "white" }}
                >
                  <FormControl style={{ width: "80%", marginTop: "10px" }}>
                    <InputLabel id="demo-mutiple-chip-label">
                      Parent Category/level 1
                    </InputLabel>
                    <Select
                      native
                      value={parentCategory}
                      onChange={(e) => {
                        setTrigger((prev) => !prev);
                        setParentCategory(e.target.value);
                        if (parentCategory === "") {
                          setSubCategorySelect("");
                        }
                      }}
                    >
                      <option aria-label="None" value="" />
                      {categoryData.map((item) => {
                        return <option value={item.name}>{item.name}</option>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  align="center"
                  style={{ marginTop: 10 }}
                  item
                  xs={12}
                  lg={6}
                  style={{ backgroundColor: "white" }}
                >
                  {trigger && (
                    <FormControl style={{ width: "80%" }}>
                      <InputLabel id="demo-mutiple-chip-label">
                        Sub Category/ level 2
                      </InputLabel>
                      <Select
                        native
                        value={subCategorySelect}
                        onChange={(e) => {
                          setSubCategorySelect(e.target.value);
                        }}
                      >
                        <option aria-label="None" value="" />
                        {subCategory.map((item) => {
                          return <option value={item.name}>{item.name}</option>;
                        })}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                {trigger && (
                  <Grid
                    align="center"
                    style={{ marginTop: 10 }}
                    item
                    xs={12}
                    lg={6}
                    style={{ backgroundColor: "white" }}
                  >
                    <CategorySelect
                      genre={genre}
                      setGenre={(e) => setGenre(e.target.value)}
                    />
                  </Grid>
                )}

                <Grid
                  align="center"
                  xs={12}
                  item
                  style={{ backgroundColor: "white" }}
                >
                  <TextField
                    style={{ width: "90%", marginTop: 40 }}
                    id="outlined-multiline-static"
                    label={
                      letterCounter <= 200
                        ? "Enter Book Description"
                        : "Enter Book Description (atleast 300 characters)"
                    }
                    multiline
                    required
                    inputProps={{ maxLength: 500 }}
                    rows={10}
                    onChange={(e) => {
                      setDescription(e.target.value);

                      setLettercounter(500 - e.target.value.length);
                    }}
                    value={description}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  style={{ marginLeft: 30, backgroundColor: "white" }}
                  align="start"
                  item
                  xs={12}
                >
                  <Typography
                    style={
                      letterCounter > 300
                        ? { color: "green", backgroundColor: "white" }
                        : letterCounter > 100
                        ? { color: "#f47800" }
                        : { color: "red" }
                    }
                  >
                    {letterCounter} characters left
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ backgroundColor: "white" }}>
                  <ImageInput
                    nameAttr={"BookImage"}
                    idAttr={"book-img"}
                    uploadText={"jpeg/jpg/png/gif"}
                    getter={image}
                    setter={(e) => setImg(e.target.files[0])}
                    helperText={", size limit: 40 mb"}
                  />
                </Grid>
                <Grid
                  align="center"
                  item
                  xs={12}
                  style={{ backgroundColor: "white" }}
                >
                  <Button
                    style={{
                      width: "40%",
                      marginTop: 30,
                      marginBottom: 30,
                      backgroundColor: "#3C4564",
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      if (description.length <= 200) {
                        toast.error(
                          "Book Description should be atleast 300 characters long",
                          {
                            position: toast.POSITION.TOP_CENTER,
                          }
                        );
                      } else {
                        const formData = new FormData();
                        formData.append("title", title);
                        formData.append("titleUrdu", titleUrdu);
                        formData.append("narrator", narrator);
                        formData.append("contributor", contributor);
                        formData.append("author", author);
                        formData.append("image", image);
                        formData.append("description", description);
                        formData.append("categories", parentCategory);
                        formData.append("subCategory", subCategorySelect);
                        for (var i = 0; i < genre.length; i++) {
                          formData.append("genre[]", genre[i]);
                        }
                        // for (var key of formData.entries()) {
                        //   console.log(key[0] + ", " + key[1]);
                        // }
                        bookService
                          .addBook(formData, config)
                          .then((res) => {
                            console.log(res);
                            setTitle("");
                            setNarrator("");
                            setContributor("");
                            setTitleUrdu("");
                            setAuthor("");
                            setImg(null);
                            setDescription("");
                            setGenre([]);
                            setParentCategory("");
                            setSubCategorySelect("");

                            props.history.push("/addchapter/" + res._id);
                          })
                          .catch((err) => {
                            toast.error(err?.response.data, {
                              position: toast.POSITION.TOP_CENTER,
                            });
                          });
                      }
                    }}
                  >
                    Add Book
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid data-aos="fade-left">
              <img className={classes.contributeImage} src="/images/i1.png" />
            </Grid>
          </Grid>
        </Container>
        <svg
          className={classes.curvedDivsvg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 318"
        >
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,128L30,144C60,160,120,192,180,202.7C240,213,300,203,360,192C420,181,480,171,540,176C600,181,660,203,720,186.7C780,171,840,117,900,128C960,139,1020,213,1080,245.3C1140,277,1200,267,1260,240C1320,213,1380,171,1410,149.3L1440,128L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
          ></path>
        </svg>
      </div>
    </Auth>
  );
};

export default withRouter(AddAudioBook);
