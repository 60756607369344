/* eslint-disable */
import React from "react";
import DropDownMenu from "./DropDownMenu";
import {
  AppBar,
  Toolbar,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "./styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
// import { ReactComponent as Logo } from "../../assets/logo/LOGO.svg";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DrawerComponent from "./MobileView";
import bookService from "../../services/BookService";
import userService from "../../services/UserService";
import Auth from "../Auth/Auth";
import NotificationTabs from "./NotificationTabs";

const Navbar = (props) => {
  const [dot, setDot] = React.useState(JSON.parse(localStorage.getItem("dot"))?true:false);
  const [stories, setStories] = React.useState([]);
  const [storiesApproved, setStoriesApproved] = React.useState([]);
  const [storiesDisapproved, setStoriesDisapproved] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    localStorage.setItem("dot", false);
    // console.log(JSON.parse(localStorage.getItem("dot")));
    setDot(JSON.parse(localStorage.getItem("dot")));
  };
  React.useEffect(() => {
    bookService
      .getAudioBooksUnapproved()
      .then((data) => {
        if(JSON.parse(localStorage.getItem("dot_checker")) != data.length){
          localStorage.setItem("dot",true);
          setDot(true);
          
        }
        setStories(data);
        localStorage.setItem('dot_checker',data.length);
      })
      .catch((err) => {
        console.log(err);
      });

      bookService
      .getAudioBooksApproved()
      .then((data) => {
        setStoriesApproved(data);
      })
      .catch((err) => {
        console.log(err);
      });

      bookService
      .getAudioBooksDisapproved()
      .then((data) => {
        setStoriesDisapproved(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const push_the_link = (val)=>{
    props.history.push("/singleBook/"+val);
    
  }
  return (
    <div>
      <AppBar
        className={classes.appbar}
        position="static"
        elevation={0}
        color="primary"
      >
        <Toolbar>
          {isMatch ? (
            <>
              <DrawerComponent />
            </> 
          ) : (
            userService.isLoggedIn() ?
            <>
              {/* <Logo className={classes.logo} /> */}
              <div className={classes.tabsContainer}>
                <nav style={{position:"absolute",top:5,left:10}}>
                  <img width="40px" src="images/logo.png"/>
                </nav>
                <Link to="/">
                  <div  class="dropdown" style={{ marginLeft: "30px" }}>
                    <a class="dropbtn">Home</a>
                  </div>
                </Link>

                <Link to="/audiobooks">
                  <div class="dropdown">
                    <a class="dropbtn">Audio Books</a>
                  </div>
                </Link>
                <Link to="/contribute">
                  <div class="dropdown">
                    <a class="dropbtn">Contribute</a>
                  </div>
                </Link>
              </div>

              <div className={classes.tabsContainer2}>
                <Badge
                  onClick={handleClick}
                  style={{ marginRight: 10 }}
                  color="secondary"
                  variant={dot ? "dot" : ""}
                >
                  <NotificationsIcon />
                </Badge>
                <Menu
                  style={{ marginTop: 30 }}
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <NotificationTabs push_the_link={push_the_link} stories={stories} approvedStories={storiesApproved} disapprovedStories={storiesDisapproved} handleClose={handleClose}/>
                  
                </Menu>
                <Link to="/">
                  <div class="dropdown">
                    <a class="dropbtn">
                      <b>
                        Welcome, {userService.getLoggedInUser()?.first_name}
                      </b>
                    </a>
                  </div>
                </Link>
                <div class="dropdown">
                  <DropDownMenu />
                </div>
              </div>
            </>:<>
            <div className={classes.tabsContainer}>
                <Link to="/">
                  <div class="dropdown" style={{ marginLeft: "30px" }}>
                    <a class="dropbtn">Home</a>
                  </div>
                </Link>

                <Link to="/audiobooks">
                  <div class="dropdown">
                    <a class="dropbtn">Audio Books</a>
                  </div>
                </Link>
             
              </div>

            <Link to="/signin">
                  <div class="dropdown">
                    <a class="dropbtn">
                     Login
                    </a>
                  </div>
                </Link>
                <Link to="/signup">
                  <div class="dropdown">
                    <a class="dropbtn">
                     Register
                    </a>
                  </div>
                </Link>
            </>
          )}
        </Toolbar>
      </AppBar>
      {/* Menu */}
    </div>
  );
};

export default withRouter(Navbar);
