import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import categoryService from "../../services/CategoryService";
import { withRouter } from "react-router";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Auth from "../Auth/Auth";

// import Auth from "../../auth/Auth";
const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "10px",
  },
  media: {
    height: 170,
  },
});

const Genre = (props) => {
  const [categories, setCategories] = useState([]);
  // const [loader, setLoader] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    categoryService
      .getCategory()
      .then((data) => {
        setCategories(data);
        // setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(categories);

  return (
    <Auth>
      <Container maxWidth="lg" style={{ marginTop: "50px" }}>
        <Grid container spacing={4}>
          {categories.map((category, index) => {
            return (
              <Grid item xs={6} md={3}>
                <Card
                  className={classes.root}
                  onClick={(e) => {
                    props.history.push("/genre/" + genre.name);
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {category.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Auth>
  );
};

export default withRouter(Genre);
