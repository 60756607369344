import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  connectWallet: {
    borderRadius: 60,

    backgroundColor: "#5E0200",
    "&:hover": {
      backgroundColor: "#5E0200",
    },
  },
  logo: {
    [theme.breakpoints.down("xl")]: {
      marginLeft: 45,
      width: 150,
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: 45,
      width: 170,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 35,
      width: 120,
    },

    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: 90,
    },
  },
  tabsContainer: {
    marginRight: "auto",
  },
  tabsContainer2: {
    
    marginLeft: "auto",
  },
  iconLogo: {
    color: "yellow",
    fontSize: "3rem",
  },
  icons: {
    fontSize: "1.4rem",
  },
  appbar: {
    
    backgroundColor: "transparent",
    color: "black",
  },
  iconButtonContainer: {
    marginRight: "auto",
    color: "white",
  },
  connectWalletMobile: {
    backgroundColor: "#5E0200",
    "&:hover": {
      backgroundColor: "#5E0200",
    },
    borderRadius: 8,
    marginLeft: 3,
    width: 250,
    padding: 8,
    marginTop: 20,
  },
  center: {
    textAlign: "center",
  },
  typograph: {
    textAlign: "center",
    width: 170,
  },
  menuIconToggle: {
    color: "black",

    fontSize: "2rem",
  },
}));
export { useStyles };
