import React from "react";
import Gridpage from "./Components/LandingPage/LandingPage";
import Signin from "./Components/SignIn/SignIn";
import Signup from "./Components/SignUp/SignUp";
import AudioPlayer from "./Components/AudioPlayer/AudioPlayer";
import SingleBook from "./Components/Books/SingleBook";
import SingleAudioBook from "./Components/AddAudioBook/SingleAudioBook";
import Category from "./Components/Category/Category";
import AddChapter from "./Components/AudioBookChapters/AddChapter";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
// import GenreSpecificStory from "./Components/Category/GenreSpecificStory";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import AppBar from "./Components/AppBar/AppBar";
import AddAudioBook from "./Components/AddAudioBook/AddAudioBook";
import MyAudioBook from "./Components/AddAudioBook/MyAudioBook";
import AudioBook from "./Components/AudioBook/AudioBook";
import Footer from "./Components/Footer/Footer";

const App = withRouter(({ location }) => {
  return (
    <div>
      {location.pathname !== "/signin" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/privacyPolicy" && <AppBar />}
      <Switch>
        {/* <Route path="/genre/:name" component={GenreSpecificStory} /> */}
        <Route exact path="/" component={Gridpage} />
        <Route exact path="/myaudiobooks" component={MyAudioBook} />
        <Route exact path="/audiobooks" component={AudioBook} />
        <Route exact path="/singleBook/:id" component={SingleBook} />
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/contribute" component={AddAudioBook} />
        <Route exact path="/addchapter/:id" component={AddChapter} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/editAudioBook/:id" render={() => <SingleAudioBook />} />
        <Route exact path="/category" component={Category} />
        <Route exact path="/audioplayer/:id" render={() => <AudioPlayer />} />
      </Switch>
      <Footer />
      <ToastContainer autoClose={5000} closeOnClick />
    </div>
  );
});
export default App;
