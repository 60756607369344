import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Container from "@material-ui/core/Container";
const ReactLoader = (props) => {
  //other logic
  return (
    <Container style={{ marginTop: 100 }} align="center" maxWidth="lg">
      <Loader
        type={props.type}
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //10 secs
      />
    </Container>
  );
};

export default ReactLoader;
